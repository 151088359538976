<template>
  <v-container>
    <v-card class="thankyou elevation-0 pa-5">
      <v-row
        no-gutters
        class="flex-wrap justify-center flex-md-row-reverse align-center"
      >
        <v-col cols="12" sm="5" md="3">
          <v-img src="/img_layout/ananas.png"></v-img>
        </v-col>
        <v-col cols="12" md="9" class="d-flex flex-column align-center">
          <h1 class="mt-0 font-weight-bold primary-text base">
            {{ $t("thankyou.title") }}
          </h1>
          <h2 class="text-center">
            {{
              communication
                ? $t("thankyou.subtitle.recieveCommunication")
                : $t("thankyou.subtitle.contactUs")
            }}
          </h2>
          <v-card-actions class="mt-3"
            ><v-btn
              x-large
              class="px-9 elevation-0"
              :to="{
                name: 'Home'
              }"
              >{{
                communication
                  ? $t("thankyou.cta.recieveCommunication")
                  : $t("thankyou.cta.contactUs")
              }}</v-btn
            ></v-card-actions
          >
        </v-col>
      </v-row>
    </v-card>
    <category-block
      :target="category"
      position="position2"
      class="category-block category-block-2"
      :container="false"
    />
    <category-block
      :target="category"
      position="position3"
      class="category-block category-block-3"
      :container="false"
    />
    <category-block
      :target="category"
      position="position4"
      class="category-block category-block-4"
      :container="false"
    />
  </v-container>
</template>
<style lang="scss">
.thankyou {
  h2 {
    color: var(--v-primary-lighten1);
  }
  a.v-btn {
    background-color: var(--v-primary-lighten3) !important;
  }
  .v-btn__content {
    color: $white !important;
  }
}
</style>
<script>
import categoryMixins from "~/mixins/category";

export default {
  name: "Thankyou",
  mixins: [categoryMixins],
  props: {
    communication: { type: Boolean, required: false, default: false }
  }
};
</script>
